import {createSlice} from '@reduxjs/toolkit'
import {taskApi} from "../services/taskService";
import {createStandaloneToast} from '@chakra-ui/toast'
import {fileApi} from "../services/fileService";
import {userApi} from "../services/userService";
import i18n from "i18next"
import {fileTypeApi} from "../services/fileTypeService";
import {predefinedTaskApi} from "../services/predefinedTaskService";
import {registerApi} from "../services/registerService";
import {linkedFilesService} from "../services/linkedFilesService";
import * as Sentry from "@sentry/react";
import {companyApi} from "../services/companyService";

const {toast} = createStandaloneToast()
const {t} = i18n


const toastSlice = createSlice({
    name: 'toast',
    initialState: {},
    reducers: {
        invalidLoginToast: () => {
            toast({
                title: t('toast.warning.title'),
                variant: 'top-accent',
                description: t('toast.invalidCredentials'),
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
        },
        accessDeniedToast: () => {
            toast({
                title: t('toast.warning.title'),
                variant: 'top-accent',
                description: t('toast.accessDenied'),
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
        },
        copyToast: (
            state,
            {payload}
        ) => {
            toast({
                title: t('toast.success.title'),
                variant: 'top-accent',
                description: t(payload),
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(fileApi.endpoints.uploadFile.matchFulfilled, () => {
                toast({
                    title: t('toast.success.title'),
                    variant: 'top-accent',
                    description: t('toast.uploadFile'),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        )
        builder.addMatcher(fileApi.endpoints.updateFileInfo.matchFulfilled,
            () => {
                toast({
                    title: t('toast.success.title'),
                    variant: 'top-accent',
                    description: t('toast.editFile'),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        )
        builder.addMatcher(taskApi.endpoints.createTask.matchFulfilled,
            () => {
                toast({
                    title: t('toast.success.title'),
                    variant: 'top-accent',
                    description: t('toast.createTask', {count: 1}),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        )
        builder.addMatcher(taskApi.endpoints.createBulkTask.matchFulfilled,
            () => {
                toast({
                    title: t('toast.success.title'),
                    variant: 'top-accent',
                    description: t('toast.createTask', {count: 2}),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        )
        builder.addMatcher(taskApi.endpoints.updateTaskStatus.matchFulfilled,
            () => {
                toast({
                    title: t('toast.success.title'),
                    variant: 'top-accent',
                    description: t('toast.updateTaskStatus'),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        )
        builder.addMatcher(userApi.endpoints.changePassword.matchFulfilled,
            () => {
                toast({
                    title: t('toast.success.title'),
                    variant: 'top-accent',
                    description: t('toast.changePassword'),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        )
        builder.addMatcher(fileTypeApi.endpoints.createFileType.matchFulfilled,
            () => {
                toast({
                    title: t('toast.success.title'),
                    variant: 'top-accent',
                    description: t('toast.createFileType'),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        )
        builder.addMatcher(predefinedTaskApi.endpoints.createPredefinedTask.matchFulfilled,
            () => {
                toast({
                    title: t('toast.success.title'),
                    variant: 'top-accent',
                    description: t('toast.createPredefinedTask'),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        )
        builder.addMatcher(userApi.endpoints.inviteUser.matchFulfilled,
            () => {
                toast({
                    title: t('toast.success.title'),
                    variant: 'top-accent',
                    description: t('toast.inviteUserSuccessful'),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        )
        builder.addMatcher(registerApi.endpoints.register.matchFulfilled,
            () => {
                toast({
                    title: t('toast.success.title'),
                    variant: 'top-accent',
                    description: t('toast.registrationSuccessful'),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        )
        builder.addMatcher(userApi.endpoints.inviteUser.matchRejected,
            (state, action) => {
                if (action.payload.status === 422 && action.payload.data.message === 'User is already in the company') {
                    toast({
                        title: t('toast.warning.title'),
                        variant: 'top-accent',
                        description: t('toast.inviteUserExisting'),
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            }
        )
        builder.addMatcher(linkedFilesService.endpoints.createBulkLinkedFiles.matchFulfilled,
            () => {
                toast({
                    title: t('toast.success.title'),
                    variant: 'top-accent',
                    description: t('toast.linkFile', {count: 2}),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        )
        builder.addMatcher(linkedFilesService.endpoints.deleteLinkedFiles.matchFulfilled,
            () => {
                toast({
                    title: t('toast.success.title'),
                    variant: 'top-accent',
                    description: t('toast.unlinkFile', {count: 2}),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        )
        builder.addMatcher(linkedFilesService.endpoints.createBulkLinkedFiles.matchRejected,
            (state, action) => {
                if (action.payload.status === 422) {
                    Sentry.withScope((scope) => {
                        scope.setExtras({payload: action?.meta?.arg?.originalArgs, response: action.payload})
                        Sentry.captureMessage('invalidLinkedFiles');
                    })
                    toast({
                        title: t('toast.warning.title'),
                        variant: 'top-accent',
                        description: t('toast.linkFileError'),
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            }
        )
        builder.addMatcher(companyApi.endpoints.updateCompany.matchFulfilled,
            () => {
                toast({
                    title: t('toast.success.title'),
                    variant: 'top-accent',
                    description: t('toast.updateCompany'),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        )
    },
})

export const {copyToast, invalidLoginToast, accessDeniedToast} = toastSlice.actions

export default toastSlice.reducer

