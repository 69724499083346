import React from "react";
import {
    Box,
    Center,
    Flex,
    Heading,
    Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import {isFilled} from "../../utils/generalHelper";
import {useTranslation} from "react-i18next";
import {
    useDeletePredefinedTaskMutation,
    useGetPredefinedTasksListQuery
} from "../../services/predefinedTaskService";
import {useGetFileTypesListQuery} from "../../services/fileTypeService";
import {IoTrash} from "react-icons/io5";
import DeleteDialog from "../DeleteDialog";
import {Role} from "../../constants/roles";
import {RoleAccess} from "../../utils/RoleAccess";
import {CellTypes} from "../../types";
import TableCell from "../Table/TableCell";

export default function PredefinedTasksTable() {
    const {data: predefinedTasks} = useGetPredefinedTasksListQuery()
    const [remove, {isLoading}] = useDeletePredefinedTaskMutation()
    const {data: fileTypes} = useGetFileTypesListQuery()
    const {t} = useTranslation()

    return (
        <Box
            bg={'white'}
            rounded={'2xl'}
            border={'1px primary var(--chakra-colors-gray-200)'}
            mt={'6'}
        >
            <Center p={8} mt={4} mb={2}>
                <Heading size={'sm'}>{t('predefinedTasks.predefinedTask', {count: 2})}</Heading>
                <Spacer/>
            </Center>
            <TableContainer w={'full'}>
                <Table variant='striped'>
                    <Thead>
                        <Tr sx={{th: {color: 'black', textAlign: 'center'}}}>
                            <Th>{t('common.name')}</Th>
                            <Th>{t('predefinedTasks.taskName')}</Th>
                            <Th>{t('predefinedTasks.taskAssignee')}</Th>
                            <Th>{t('predefinedTasks.event')}</Th>
                            <Th>{t('predefinedTasks.eventId')}</Th>
                            <Th>{t('common.createdBy')}</Th>
                            <Th>{t('common.createdAt')}</Th>
                            <RoleAccess allowedRoles={[Role.Manager]}>
                                <Th></Th>
                            </RoleAccess>
                        </Tr>
                    </Thead>
                    <Tbody
                        sx={{
                            td: {color: 'gray.700', textAlign: 'center'},
                            'tr:nth-of-type(odd) td': {bg: 'gray.50'},
                        }}
                    >
                        {predefinedTasks?.map((predefinedTask, i) => (
                            <Tr key={`b${i}`}>
                                <TableCell
                                    content={predefinedTask.name}
                                    addTd={true}
                                />
                                <TableCell
                                    content={predefinedTask.task_name}
                                    addTd={true}
                                />
                                <TableCell
                                    content={predefinedTask?.task_assignee?.first_name ?? null}
                                    subContent={predefinedTask?.task_assignee?.last_name}
                                    type={CellTypes.Name}
                                    addTd={true}
                                />
                                <TableCell
                                    content={isFilled(predefinedTask?.event) ? t(`predefinedTasks.events.${predefinedTask?.event}`) : t('predefinedTasks.events.noEvent')}
                                    addTd={true}
                                />
                                <TableCell
                                    content={fileTypes?.find(fileType => fileType.id === predefinedTask?.event_id)?.name ?? null}
                                    addTd={true}
                                />
                                <TableCell
                                    content={predefinedTask?.user?.first_name}
                                    subContent={predefinedTask?.user?.last_name}
                                    type={CellTypes.Name}
                                    addTd={true}
                                />
                                <TableCell
                                    content={predefinedTask.created_at ?? null}
                                    type={CellTypes.Date}
                                    addTd={true}
                                />
                                <RoleAccess allowedRoles={[Role.Manager]}>
                                    <Td px={0}
                                        bg={i % 2 === 0 ? "blackAlpha.100 !important" : "whiteAlpha.100 !important"}
                                    >
                                        <Flex justifyContent={'space-around'}>
                                            <DeleteDialog
                                                buttonProps={{
                                                    height: '20px',
                                                    pe: 0,
                                                    colorScheme: 'white',
                                                    isLoading: isLoading,
                                                }}
                                                icon={<IoTrash
                                                    fontSize={20}
                                                    color='var(--chakra-colors-red-400)'
                                                />}
                                                infoAlert={'predefinedTasks.delete.infoAlert'}
                                                deleteAction={() => remove(predefinedTask?.id)}
                                            />
                                        </Flex>
                                    </Td>
                                </RoleAccess>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
}
