import {
    Alert,
    AlertDialog, AlertDialogBody,
    AlertDialogContent, AlertDialogFooter, AlertDialogHeader,
    AlertDialogOverlay, AlertIcon,
    Button,
    useDisclosure
} from "@chakra-ui/react";
import React from "react";
import {useTranslation} from "react-i18next";

export default function DeleteDialog({actionLabel = undefined, buttonProps, infoAlert, icon, deleteAction}) {
    const {t} = useTranslation()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const cancelRef = React.useRef()

    return (
        <>
            <Button colorScheme='red' onClick={onOpen} {...buttonProps}>
                {actionLabel ? t(actionLabel) : null}
                {icon ? icon : null}
            </Button>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t(actionLabel)}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {infoAlert
                                ? <Alert status='info' mb={4}>
                                    <AlertIcon/>
                                    {t(infoAlert)}
                                </Alert>
                                : null
                            }

                            {t('deleteDialog.content')}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                {t('common.actions.cancel')}
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                                onClose();
                                deleteAction();
                            }} ml={3}>
                                {t('common.actions.delete')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}
