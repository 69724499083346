import React, {useState} from "react";
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    ButtonGroup,
    Center,
    Collapse,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Spacer,
    Text,
    Tooltip,
    VStack,
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {object, string} from "yup";
import {IoMdAddCircleOutline} from "react-icons/io";
import {Form, Formik} from "formik";
import AppInput from "../AppInput";
import AppSelect from "../AppSelect";
import {AccountType, BrandColorMode, DefaultBrandColor, UpdateCompanyFormValues, User} from "../../types";
import {HexColorPicker} from "react-colorful";
import {IoColorPaletteOutline} from "react-icons/io5";
import {useUpdateCompanyMutation} from "../../services/companyService";


export default function CompanySettings({user, isLoadingUser}: { user: User, isLoadingUser: boolean }) {
    const {t, i18n} = useTranslation()
    const [edit, {isLoading}] = useUpdateCompanyMutation()
    const [isOpen, setIsOpen] = useState(false);

    function handleCollapse() {
        setIsOpen((p) => !p);
    }

    const schema = object({
        name: yup.string().min(2).required(),
        legal_name: yup.string().min(2).required(),
        public_email: yup.string().email().required(),
        brand_color: string().matches(/^#([0-9A-F]{3}){1,2}$/i, 'Invalid color').required(),
        brand_color_mode: yup.string().oneOf(Object.values(BrandColorMode)),
    });

    if (isLoadingUser) {
        return <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
            <Center mb={2}>
                <HStack spacing={4}>
                    <Heading size={"sm"}>{t('settings.company.title')}</Heading>
                </HStack>
                <Spacer/>
                <Button
                    variant={"outline"}
                    w={"auto"}
                    isDisabled={true}
                    leftIcon={
                        <IoMdAddCircleOutline color="var(--chakra-colors-teal-400)"/>
                    }
                >
                    {t('settings.company.button')}
                </Button>
            </Center>
        </Box>
    }

    return (
        <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
            <Center mb={2}>
                <HStack spacing={4}>
                    <Heading size={"sm"}>{t('settings.company.title')}</Heading>
                    {/*/!*todo if not valid show msg and red*!/*/}
                    {/*<Tag size={'md'} variant='solid' colorScheme='teal'>*/}
                    {/*    {t(`settings.company.accountTypes.${user?.company?.account_type}`)}*/}
                    {/*</Tag>*/}
                </HStack>
                <Spacer/>
                <Button
                    variant={"outline"}
                    w={"auto"}
                    onClick={handleCollapse}
                    leftIcon={
                        <IoMdAddCircleOutline color="var(--chakra-colors-teal-400)"/>
                    }
                >
                    {t('settings.company.button')}
                </Button>
            </Center>
            {/*<Text mb="4">*/}

            {/*</Text>*/}
            <Collapse in={isOpen} animateOpacity>
                <Formik<UpdateCompanyFormValues>
                    validationSchema={schema}
                    validateOnMount={true}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        edit(values)
                        setSubmitting(false);
                        resetForm()
                        handleCollapse()
                    }}
                    // enableReinitialize={true}
                    initialValues={{
                        name: user.company?.name ?? '',
                        legal_name: user.company?.legal_name ?? '',
                        public_email: user.company?.public_email ?? '',
                        brand_color: user.company?.brand_color ?? DefaultBrandColor,
                        brand_color_mode: user.company?.brand_color_mode ?? BrandColorMode.NavbarShadow,
                        // logo: '',
                        language: i18n.language,
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors,
                          setFieldValue,
                          setFieldTouched,
                      }) => (
                        <Form>
                            <Grid pt={4} templateColumns="repeat(2, 1fr)" columnGap={8} rowGap={6}>
                                <GridItem>
                                    <VStack spacing={6} align="stretch">
                                        <FormControl isInvalid={!!errors.name && touched.name}>
                                            <AppInput
                                                name={`name`}
                                                handleBlur={handleBlur}
                                                label={t('company.name')}
                                                placeholder={t('company.name')}
                                            />
                                            <FormErrorMessage>{errors.name}</FormErrorMessage>
                                        </FormControl>
                                        <FormControl isInvalid={!!errors.legal_name && touched.legal_name}>
                                            <AppInput
                                                name={`legal_name`}
                                                handleBlur={handleBlur}
                                                isDisabled={user.company?.account_type === AccountType.personal}
                                                label={t('company.legalName')}
                                                placeholder={t('company.legalName')}
                                            />
                                            <FormErrorMessage>{errors.legal_name}</FormErrorMessage>
                                        </FormControl>
                                        <FormControl isInvalid={!!errors.public_email && touched.public_email}>
                                            <AppInput
                                                name={`public_email`}
                                                handleBlur={handleBlur}
                                                isDisabled={user.company?.account_type === AccountType.personal}
                                                label={t('company.publicEmail')}
                                                placeholder={t('company.publicEmail')}
                                            />
                                            <FormErrorMessage>{errors.public_email}</FormErrorMessage>
                                        </FormControl>
                                    </VStack>
                                </GridItem>
                                <GridItem>
                                    <VStack>
                                        <FormControl isInvalid={!!errors.brand_color_mode && touched.brand_color_mode}>
                                            <AppSelect
                                                name={`brand_color_mode`}
                                                onChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={values.brand_color_mode}
                                                label={'company.brandColorModes.brandColorMode'}
                                                // placeholder={t('common.select')}
                                            >
                                                <>
                                                    <option value={BrandColorMode.None}>
                                                        {t(`company.brandColorModes.${BrandColorMode.None}`)}
                                                    </option>
                                                    <option value={BrandColorMode.NavbarShadow}>
                                                        {t(`company.brandColorModes.${BrandColorMode.NavbarShadow}`)}
                                                    </option>
                                                    {/*{Object.values(BrandColorMode).map((colorMode) => (*/}
                                                    {/*    <option key={colorMode} value={colorMode}>*/}
                                                    {/*        {t(`company.brandColorModes.${colorMode}`)}*/}
                                                    {/*    </option>*/}
                                                    {/*))}*/}
                                                </>
                                            </AppSelect>
                                            <FormErrorMessage>{errors.brand_color_mode}</FormErrorMessage>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>{t('company.brandColor')}</FormLabel>
                                            <HStack alignItems="flex-start" spacing={4}>
                                                <VStack alignItems="flex-start" spacing={2}>
                                                    <HexColorPicker
                                                        color={values.brand_color}
                                                        onChange={(color) => setFieldValue('brand_color', color)}
                                                    />
                                                </VStack>
                                                <VStack alignItems="flex-start" spacing={2}>
                                                    <Box
                                                        w="40px"
                                                        h="40px"
                                                        borderRadius="md"
                                                        bg={values.brand_color}
                                                        border="1px solid"
                                                        borderColor="gray.200"
                                                    />
                                                    <Text fontSize="sm">{values.brand_color}</Text>
                                                    <Tooltip label={t('common.actions.resetToDefault')}>
                                                        <Button
                                                            size="sm"
                                                            onClick={() => setFieldValue('color', user.company?.brand_color ?? DefaultBrandColor)}
                                                            leftIcon={<IoColorPaletteOutline/>}
                                                        >
                                                            {t('common.actions.reset')}
                                                        </Button>
                                                    </Tooltip>
                                                </VStack>
                                            </HStack>
                                        </FormControl>
                                    </VStack>
                                </GridItem>
                                <GridItem colSpan={2}>
                                    <Alert status='warning'>
                                        <AlertIcon/>
                                        {t('settings.company.note')}
                                    </Alert>
                                </GridItem>
                            </Grid>
                            <Center
                                mt={16}
                                sx={{
                                    button: {
                                        minW: 160,
                                    },
                                }}
                            >
                                <Spacer/>
                                <ButtonGroup gap={3}>
                                    <Button
                                        isLoading={isLoading}
                                        loadingText={t('common.actions.saving')}
                                        type={'submit'}
                                        variant={"primary"}
                                    >
                                        {t('common.actions.save')}
                                    </Button>
                                </ButtonGroup>
                            </Center>
                        </Form>
                    )}
                </Formik>
            </Collapse>
        </Box>
    );
}
