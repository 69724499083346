import {URL} from "../constants/API";
import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    FetchBaseQueryMeta
} from '@reduxjs/toolkit/query/react'
import {logout, setTokens} from "../slices/authSlice";
import {isFilled} from "../utils/generalHelper";
import {RootState} from "../store/store";
import {RefreshTokenResponse} from "../types";

const config = {
    apiUrl: URL.concat('/api/')
} as const;

const baseQuery = fetchBaseQuery({
    baseUrl: config.apiUrl,
});

const baseAuthenticatedQuery = fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers, {getState}) => {
        const state = getState() as RootState;
        const token = state.auth.token

        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    },
});

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
    let result = await baseAuthenticatedQuery(args, api, extraOptions);
    // let token = JSON.parse(localStorage.getItem('tokens'))
    const tokenString = localStorage.getItem('tokens');
    const token = tokenString ? JSON.parse(tokenString) : null;


    // If the request is a login_check, no re-auth attempt is needed
    const isLoginCheck =
        (typeof args === 'string' && args === 'login_check') ||
        (typeof args === 'object' && args.url === 'login_check');
    if (isLoginCheck) {
        return result;
    }

    if (
        result.error &&
        result.error.status === 401 &&
        isFilled(token?.refresh_token)
    ) {
        const refreshResult = await baseQuery(
            {
                url: 'token/refresh',
                method: 'POST',
                headers: {},
                body: {refresh_token: token?.refresh_token},
            },
            api,
            extraOptions
        ) as { data?: RefreshTokenResponse; error?: unknown };

        if (refreshResult.data) {
            api.dispatch(setTokens(refreshResult.data));

            // retry the initial query
            result = await baseAuthenticatedQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const baseApi = createApi({
        baseQuery: baseQueryWithReauth,
        tagTypes: [
            'PredefinedTasks',
            'Files',
            'LinkedFiles',
            'FileTypes',
            'EmailUpload',
            'EmailUploadAddresses',
            'Tasks',
            'CompanyDependent',
            'CurrentUser',
            'AdminCompanies',
            'AdminSubscription',
            'AdminUsers',
            'Subscription',
            'Usage',
            'AgencyClients',
            'UploadHistory',
            'UploadHistoryFile',
        ],
        endpoints: () => ({}),
    }
)
