import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "./store/store";
import {BrowserRouter} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {ChakraBaseProvider} from "@chakra-ui/react";
// import "@fontsource/inter";
// import "@fontsource/inter/400.css";
// import "@fontsource/inter/500.css";
// import "@fontsource/inter/600.css";
// import "@fontsource/inter/700.css";
import {theme} from "./theme/theme";
import './i18n';
import ReactGA from 'react-ga4';

!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? ReactGA.initialize('UA-000000-01', {testMode: true})
    : ReactGA.initialize('G-7WZ3L20TEW')

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: "https://748db6d033bf471c80899bb9a3f06de4@o233657.ingest.sentry.io/4504538783219712",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Suspense fallback={(<div>Loading</div>)}>
                <BrowserRouter>
                    <ChakraBaseProvider theme={theme}>
                        <App/>
                    </ChakraBaseProvider>
                </BrowserRouter>
            </Suspense>
        </Provider>
    </React.StrictMode>
);

// Register the service worker if the browser supports it
if ('serviceWorker' in navigator) {
    // We wait for 'load' to avoid blocking initial render
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/serviceWorker.js') // Must match the file path in 'public/'
            .then((registration) => {
                console.log('[Service Worker] Registered! Scope is:', registration.scope);

                // Listen for an update to the service worker
                registration.onupdatefound = () => {
                    // The onupdatefound event implies there's a new service worker being installed.
                    const installingWorker = registration.installing;
                    if (installingWorker) {
                        installingWorker.onstatechange = () => {
                            if (installingWorker.state === 'installed') {
                                // If there's an existing controller, this is an update
                                if (navigator.serviceWorker.controller) {
                                    console.log('[Service Worker] New version available!');

                                    // Dispatch a custom event so our React app can show a toast
                                    window.dispatchEvent(
                                        new CustomEvent('newVersionAvailable', {
                                            detail: registration,
                                        })
                                    );
                                } else {
                                    // It's the first install, so nothing special to do
                                    console.log('[Service Worker] First install - ready to work offline!');
                                }
                            }
                        };
                    }
                };
            })
            .catch((err) => console.log('[Service Worker] Registration failed: ', err));
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
