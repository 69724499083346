import {baseApi} from "./apiService";
import {UpdateCompanyFormValues} from "../types";

export const companyApi = baseApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        updateCompany: builder.mutation<undefined, UpdateCompanyFormValues>({
            query: (body) => ({
                url: `companies`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['CurrentUser'],
        }),
    }),
})

export const {
    useUpdateCompanyMutation,
} = companyApi
